import React, { HTMLAttributes, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

import {
  TeacherEvaluationPageProvider,
  TeacherEvaluationPageActions as actions,
  TeacherEvaluationPageContext,
  filteredStudentsSelector,
  activeSubmissionSelector,
  activeStudentSelector,
  assignmentDataSelector,
} from './context';
import _, { isUndefined } from 'lodash';
import { Swiper as SwiperContainer, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Swiper from 'swiper';
import { useNavigate } from 'react-router-dom';
import Select, { Option } from 'rc-select';
import { useLocalStorage, useLockBodyScroll } from 'react-use';
import IdentitySelector from '../../components/IdentitySelector';
import { CommentEditor } from './components/CommentEditor';
import { StudentFilter } from './components/StudentFilter';
import { StudentSelector } from './components/StudentSelector';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from '../App/identity.context';
import { Loader } from '../../components/Loader';
import { AuthingContext } from '../App/authing.context';
import { getMediaFileInfo } from '../../utils/media';
import { ArtworkMediaRender } from '../../components/ArtworkMediaRender';
import { Transcript } from '../Transcript';
import { SubmissionStatus } from '../../services/graphql/types/graphql';
import { useQueryState } from '../../hooks/useQueryState';

const AssignmentInfo = (): JSX.Element => {
  const { state } = useContext(TeacherEvaluationPageContext);
  return (
    <div
      className={`assignment-info__container w-[288px] flex flex-col tracking-[0.1em] text-white`}
    >
      <div className="item flex items-center border-b">
        <div className="w-[12px] text-[12px] leading-[16px] opacity-60">科目</div>
        <div className="pl-[13px] text-[20px] leading-[50px] ">
          {state.assignmentData?.assignment.course.name}
        </div>
      </div>
      <div className="item flex items-center">
        <div className="w-[12px] text-[12px] leading-[16px] opacity-60">作业</div>
        <div className="pl-[13px] text-[16px] leading-[50px] line-clamp-1">
          {state.assignmentData?.assignment.name}
        </div>
      </div>
    </div>
  );
};

const GradeArea = (): JSX.Element => {
  const {
    state,
    state: { activeStudentIndex, assignmentData },
  } = useContext(TeacherEvaluationPageContext);
  const { currentClassId, currentIdentity } = useStoreContext(IdentityContext);

  const activeStudent = useMemo(
    () => activeStudentSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );
  const activeSubmission = useMemo(
    () => activeSubmissionSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );

  const [expanded, setExpanded, remove] = useLocalStorage('teach-evaluation-page--expanded', false);

  if (!expanded || !activeSubmission) {
    return (
      <div
        className="flex flex-col items-center justify-center mr-6"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="w-[40px] h-[40px] bg-[#A6A6A6] rounded-full flex items-center justify-center self-start">
          <svg
            className="rotate-180"
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.38303 6.60824L1.76946 0.121307C1.71525 0.0437501 1.63031 0 1.54176 0H0.144862C0.0237849 0 -0.0430785 0.153125 0.0310133 0.256534L4.8271 7L0.0310133 13.7435C0.0143278 13.7669 0.00397475 13.7951 0.00113778 13.8247C-0.00169918 13.8543 0.0030946 13.8842 0.0149708 13.9109C0.0268469 13.9377 0.0453263 13.9603 0.0682961 13.976C0.0912659 13.9917 0.117799 14.0001 0.144862 14H1.54176C1.63031 14 1.71525 13.9543 1.76946 13.8787L6.38303 7.39375C6.54748 7.16108 6.54748 6.83892 6.38303 6.60824ZM11.8767 6.60824L7.26309 0.121307C7.20888 0.0437501 7.12395 0 7.0354 0H5.6385C5.51742 0 5.45056 0.153125 5.52465 0.256534L10.3207 7L5.52465 13.7435C5.50796 13.7669 5.49761 13.7951 5.49477 13.8247C5.49194 13.8543 5.49673 13.8842 5.50861 13.9109C5.52048 13.9377 5.53896 13.9603 5.56193 13.976C5.5849 13.9917 5.61143 14.0001 5.6385 14H7.0354C7.12395 14 7.20888 13.9543 7.26309 13.8787L11.8767 7.39375C12.0411 7.16108 12.0411 6.83892 11.8767 6.60824Z"
              fill="black"
              fillOpacity="0.65"
            />
          </svg>
        </div>
        <div className="mt-[3px] text-[#A6A6A6]">成 绩</div>
      </div>
    );
  }

  return (
    <div
      className={`grade-area__container pb-[18px] bg-[#4D4D4D] rounded-[20px] overflow-hidden mr-6`}
    >
      <header className="bg-[#A6A6A6] px-[18px] flex items-center">
        <div>成 绩</div>
        <svg
          className="ml-auto relative"
          onClick={() => setExpanded(!expanded)}
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.38303 6.60824L1.76946 0.121307C1.71525 0.0437501 1.63031 0 1.54176 0H0.144862C0.0237849 0 -0.0430785 0.153125 0.0310133 0.256534L4.8271 7L0.0310133 13.7435C0.0143278 13.7669 0.00397475 13.7951 0.00113778 13.8247C-0.00169918 13.8543 0.0030946 13.8842 0.0149708 13.9109C0.0268469 13.9377 0.0453263 13.9603 0.0682961 13.976C0.0912659 13.9917 0.117799 14.0001 0.144862 14H1.54176C1.63031 14 1.71525 13.9543 1.76946 13.8787L6.38303 7.39375C6.54748 7.16108 6.54748 6.83892 6.38303 6.60824ZM11.8767 6.60824L7.26309 0.121307C7.20888 0.0437501 7.12395 0 7.0354 0H5.6385C5.51742 0 5.45056 0.153125 5.52465 0.256534L10.3207 7L5.52465 13.7435C5.50796 13.7669 5.49761 13.7951 5.49477 13.8247C5.49194 13.8543 5.49673 13.8842 5.50861 13.9109C5.52048 13.9377 5.53896 13.9603 5.56193 13.976C5.5849 13.9917 5.61143 14.0001 5.6385 14H7.0354C7.12395 14 7.20888 13.9543 7.26309 13.8787L11.8767 7.39375C12.0411 7.16108 12.0411 6.83892 11.8767 6.60824Z"
            fill="black"
            fillOpacity="0.65"
          />
        </svg>
      </header>
      <div className="px-6">
        <AssignmentInfo></AssignmentInfo>
      </div>
      <div className="text-white pl-6">
        <div>提交时间： {format(+activeSubmission.createAt, 'yyyy-MM-dd HH:mm:ss')}</div>
        <div>最后修改时间： {format(+activeSubmission.updateAt, 'yyyy-MM-dd HH:mm:ss')}</div>
      </div>
      <div className="px-6">
        {currentIdentity?.identity === 'teacher' && (
          <CommentEditor artworkId={activeSubmission.artworks?.[0]?.artworkId}></CommentEditor>
        )}

        {currentIdentity?.identity === 'ta' &&
          ([
            SubmissionStatus.Submitted,
            SubmissionStatus.TaApproved,
            SubmissionStatus.Completed,
            SubmissionStatus.CompletedButNoGrade,
          ].includes(activeSubmission.submissionStatus) ? (
            <CommentEditor artworkId={activeSubmission.artworks?.[0]?.artworkId}></CommentEditor>
          ) : (
            <div className="text-white text-3xl w-full h-full flex items-start justify-center py-20">
              等待学生提交
            </div>
          ))}
      </div>
    </div>
  );
};

const AssignmentCard = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const {
    state,
    state: { activeStudentIndex, showTranscriptPreview },
    dispatch,
    toggleStarStatus,
  } = useContext(TeacherEvaluationPageContext);
  const { currentClass, currentSchool, currentIdentity } = useStoreContext(IdentityContext);
  const role = currentIdentity!.identity;
  const { user } = useStoreContext(AuthingContext);

  const filteredStudent = useMemo(() => filteredStudentsSelector(state, role), [state]);

  const currentSubmission = useMemo(() => activeSubmissionSelector(state, role), [state]);

  const artworkId = useMemo(() => currentSubmission?.artworks[0]?.artworkId, [currentSubmission]);
  const isStaredRemote = useMemo(
    () => currentSubmission?.artworks[0]?.tags?.map((t) => t.name).includes('internal--star'),
    [currentSubmission],
  );
  const [isStaredLocal, setIsStarLocal] = useState<boolean | undefined>(undefined);
  const isStared = isStaredLocal ?? isStaredRemote ?? false;

  const toggleStar = () => {
    setIsStarLocal(!isStared);
    toggleStarStatus(currentSubmission.submissionId, artworkId, isStared).catch(console.error);
  };
  // useEffect(() => console.info(`AssignmentCard`, currentArtwork), [currentArtwork]);

  const saveFile = async () => {
    const { ext } = await getMediaFileInfo(
      currentSubmission?.artworks[0]?.medias[0].url.replace('http://', 'https://'),
    );
    saveAs(
      currentSubmission?.artworks[0]?.medias[0].url.replace('http://', 'https://'),
      `${currentSchool?.name}-${currentClass?.name}-${filteredStudent[activeStudentIndex].user.name}.${ext}`,
    );
  };

  return (
    <div className="flex flex-col flex-1 w-full h-full justify-center">
      {role === 'teacher' && (
        <div className="flex">
          <svg
            // className='self-end'
            onClick={toggleStar}
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 0L9.98633 4.07786L14.6329 5.18237L11.523 8.80714L11.9084 13.5676L7.5 11.73L3.09161 13.5676L3.47703 8.80714L0.367076 5.18237L5.01367 4.07786L7.5 0Z"
              fill={isStared ? 'red' : '#C4C4C4'}
            />
          </svg>
          <div className="w-[15px] h-[14px] ml-[5px]" onClick={saveFile}>
            <svg
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              preserveAspectRatio="xMidYMid meet"
              fill="#c4c4c4"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm0 47L16 33.695h10.857V15h10.285v18.695H48L32 49z"></path>
              </g>
            </svg>
          </div>
        </div>
      )}
      <SwiperContainer
        className={cx(className, 'relative')}
        modules={[Pagination, Navigation]}
        navigation={{
          prevEl: '.assignment-swiper__button--prev',
          nextEl: '.assignment-swiper__button--next',
        }}
        pagination={{
          type: 'fraction',
          clickable: true,
        }}
        centerInsufficientSlides
        spaceBetween={0}
        slidesPerView={'auto'}
        style={{ margin: '10px auto' }}
        onSwiper={(swiper) => {}}
        onSlideChange={(swiper) => {}}
      >
        {children}
        <div className="assignment-swiper__button--prev absolute h-full w-[10%] left-0 top-0 z-10"></div>
        <div className="assignment-swiper__button--next absolute h-full w-[10%] right-0 top-0 z-10"></div>
      </SwiperContainer>
    </div>
  );
};

const AssignmentSelector = (): JSX.Element => {
  const {
    state,
    state: { activeStudentIndex, showTranscriptPreview },
    dispatch,
  } = useContext(TeacherEvaluationPageContext);
  const { currentIdentity } = useStoreContext(IdentityContext);
  const filteredStudent = useMemo(
    () => filteredStudentsSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );

  const [swiper, setSwiper] = useState<Swiper | null>(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeStudentIndex);
    }
  }, [activeStudentIndex, swiper]);

  return (
    <SwiperContainer
      centeredSlides
      direction={'vertical'}
      slidesPerView={1}
      spaceBetween={0}
      className="assignment-selector__container"
      onSwiper={(swiper) => setSwiper(swiper)}
      onSlideChange={(swiper) => dispatch(actions.setActiveStudentIndex(swiper.activeIndex))}
    >
      {filteredStudent.map((dStudent, iStudent) => {
        const medias =
          dStudent.submissions
            ?.map((a) => a.artworks)
            .flat()
            .map((a) => a.medias)
            .flat() ?? [];

        if (!dStudent.submissions || dStudent.submissions.length === 0) {
          return (
            <SwiperSlide key={`assignment-selector__container--item-${iStudent}`}>
              <div className="flex items-center justify-center text-3xl w-full text-white">
                作业未提交
              </div>
            </SwiperSlide>
          );
        }

        if (showTranscriptPreview) {
          return (
            <SwiperSlide key={`assignment-selector__container--item-${iStudent}`}>
              <Transcript submissionId={dStudent.submissions?.[0]?.submissionId}></Transcript>
            </SwiperSlide>
          );
        }

        return (
          <SwiperSlide key={`assignment-selector__container--item-${iStudent}`}>
            <AssignmentCard className="item">
              {medias.map((media, iMedia: number) => {
                return (
                  <SwiperSlide key={JSON.stringify(media)}>
                    <ArtworkMediaRender
                      url={media?.url.replace('http://', 'https://')}
                    ></ArtworkMediaRender>
                  </SwiperSlide>
                );
              })}
            </AssignmentCard>
          </SwiperSlide>
        );
      })}
    </SwiperContainer>
  );
};

const GroupSelector: React.FC = () => {
  const { currentSchoolId, userIdentities, setCurrentSchoolId, setCurrentClassId, currentClass } =
    useStoreContext(IdentityContext);
  const {
    state: { assignmentData },
    currentGroupId,
    setCurrentGroupId,
    dispatch: dispatchTeacherEvaluationPage,
    toggleStarStatus,
  } = useContext(TeacherEvaluationPageContext);

  const isGroupAssignment = useMemo(
    () => assignmentData?.assignment.isGroupAssignment,
    [assignmentData],
  );
  const availableIdentities = useMemo(
    () =>
      userIdentities.userAssignmentGroups.filter(
        (uag) => uag.assignmentGroup.schoolId === currentSchoolId,
      ),
    [userIdentities, currentSchoolId],
  );

  const handleGroupChange = (value: string) => {
    setCurrentGroupId?.(value);
  };

  if (!isGroupAssignment || !userIdentities) {
    return <></>;
  }

  return (
    <Select
      className="ml-[40px]"
      style={{ width: 'auto', fontSize: '18px' }}
      value={currentGroupId}
      onChange={handleGroupChange}
      dropdownMatchSelectWidth={200}
      disabled={availableIdentities?.length === 1}
      // showArrow={availableIdentities?.length !== 1}
      dropdownClassName="z-[10000]"
    >
      {availableIdentities?.map((c, i) => {
        return <Option key={c.assignmentGroup.assignmentGroupId}>{c.assignmentGroup.name}</Option>;
      })}
    </Select>
  );
};

const PageContainer: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch, toggleStarStatus } = useContext(TeacherEvaluationPageContext);
  const { userIdentities, currentIdentity, availableClasses } = useStoreContext(IdentityContext);
  const role = currentIdentity?.identity;

  const [courseId, setCourseId] = useQueryState('courseId', '');

  const assignmentData = useMemo(() => assignmentDataSelector(state), [state]);
  const isGroupAssignment = assignmentData?.assignment.isGroupAssignment;

  useLockBodyScroll();

  if (isUndefined(isGroupAssignment)) {
    return <Loader fullscreen />;
  }

  return (
    <>
      <div className="flex ml-auto text-[18px] absolute top-[37px] left-1/2 translate-x-[-50%] text-[#979797] z-10">
        <IdentitySelector
          enableSchool
          enableClass={!isGroupAssignment}
          schoolFilter={(schoolId) => {
            const group = userIdentities.userAssignmentGroups.filter(
              (uag) => uag.assignmentGroup.schoolId === schoolId,
            );
            return group.length !== 0;
          }}
          classFilter={(classId) => {
            return (
              availableClasses
                ?.find((c) => c.classId === classId)
                ?.courses.map((co) => co.courseId)
                .includes(courseId) ?? true
            );
          }}
        >
          <GroupSelector></GroupSelector>
        </IdentitySelector>
      </div>
      <div className="teacher-evaluation-page__container">
        <div className="class-nav"></div>
        <StudentSelector></StudentSelector>
        <div className="assignment-nav">
          <AssignmentSelector />
        </div>
        <div className={cx('grader')}>
          <GradeArea />
        </div>
        <StudentFilter></StudentFilter>
      </div>
    </>
  );
};

const TeacherEvaluationPage = (): JSX.Element => {
  return (
    <TeacherEvaluationPageProvider>
      <PageContainer></PageContainer>
    </TeacherEvaluationPageProvider>
  );
};

export default TeacherEvaluationPage;
