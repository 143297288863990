import { useMemo, useRef, useState } from 'react';
import { useStoreContext } from '../../../../factorys/useStoreContext';
import { HomePageStoreContext } from '../../store';
import {
  HomePageCourseFragmentDoc,
  HomePageCourseLessonFragmentDoc,
} from '../../../../services/graphql/types/graphql';

import { FragmentType, getFragmentData } from '../../../../services/graphql/types';
import _ from 'lodash';
import { LessonScrollView } from '../LessonScrollView';

export const CourseView = ({
  data,
}: {
  data: FragmentType<typeof HomePageCourseFragmentDoc>;
}): JSX.Element => {
  const {
    memos: { homePageData },
  } = useStoreContext(HomePageStoreContext);

  const course = getFragmentData(HomePageCourseFragmentDoc, data);

  const currentSchool = useMemo(() => homePageData.school, [homePageData]);
  const currentClass = useMemo(() => homePageData.class, [homePageData]);

  if (!currentSchool || !currentClass) {
    return <div></div>;
  }

  return (
    <div className="translate-x-0 text-white">
      <div className="bg-[#797979] h-[4.17vw] sticky top-0 left-0 z-[1000] flex pl-[2.22vw] space-x-[2.5vw]">
        <div className="flex items-center text-[1.67vw]">
          <div className="vertical-lr text-[0.83vw]">课程</div>
          <div className="ml-[0.56vw] whitespace-nowrap">
            <span className="font-bold whitespace-nowrap">{course.name.split('_')[0]}</span>
            <span className="ml-[0.56vw] whitespace-nowrap">{course.name.split('_')[1]}</span>
          </div>
        </div>
        <div className="flex items-center text-[1.25vw]">
          <div className="vertical-lr text-[0.83vw]">班级</div>
          <div className="ml-[0.56vw] whitespace-nowrap">{currentClass.name}</div>
        </div>
      </div>
      <div className="flex flex-col">
        {course?.lessons.map((lesson) => {
          const lessonId = getFragmentData(HomePageCourseLessonFragmentDoc, lesson).lessonId;
          return <LessonScrollView key={lessonId} data={lesson}></LessonScrollView>;
        })}
      </div>
    </div>
  );
};
