import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  createContext,
  PropsWithChildren,
  ReducerState,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { IdentityContext } from '../App/identity.context';

import { useQuery } from '@apollo/client';
import { AuthingContext } from '../App/authing.context';

import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';
import {
  GetAssignmentUploadPageDataDocument,
  GetAssignmentUploadPageDataQuery,
} from '../../services/graphql/types/graphql';

import * as Sentry from '@sentry/react';

const storageName = 'AssignmentUploadPageState';

export const defaultState = {
  assignmentUploadPageData: undefined as undefined | GetAssignmentUploadPageDataQuery,
};

const { actions, reducer } = createSlice({
  name: storageName,
  initialState: defaultState,
  reducers: {
    setUserHomePageData: (state, action) => {
      state.assignmentUploadPageData = action.payload;
    },
  },
});

export type AssignmentUploadPageStorageState = ReducerState<typeof reducer>;

export const AssignmentUploadPageStoreContext = createContext<
  | {
      state: AssignmentUploadPageStorageState;
      dispatch: React.Dispatch<React.ReducerAction<typeof reducer>>;
      // memos: {};
    }
  | undefined
>(undefined);

export const AssignmentUploadPageStoreContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const { assignmentId } = useParams();
  if (!assignmentId) {
    throw new Error('assignmentId is required');
  }

  const { user, role } = useContext(AuthingContext);

  const {
    loading,
    error,
    data: assignmentUploadPageData,
  } = useQuery(GetAssignmentUploadPageDataDocument, {
    variables: {
      assignmentId,
    },
  });

  // useEffect(() => console.info(homePageData), [homePageData]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  if (loading) {
    return <Loader fullscreen />;
  }

  return (
    <AssignmentUploadPageStoreContext.Provider
      value={{
        state: {
          assignmentUploadPageData,
        },
        dispatch,
      }}
    >
      {children}
    </AssignmentUploadPageStoreContext.Provider>
  );
};

export const withStoreProvider = (Component: () => JSX.Element) => {
  return function ComponentWithStoreProvider(props: any) {
    return (
      <AssignmentUploadPageStoreContextProvider>
        <Component {...props} />
      </AssignmentUploadPageStoreContextProvider>
    );
  };
};

export const HomePageActions = actions;
