import Axios from 'axios';

export const getMediaFileInfo = async (url: string) => {
  const {
    headers: { 'content-type': contentType },
  } = await Axios.head(url);

  switch (contentType) {
    case 'image/png':
      return { ext: 'png', type: 'image' } as const;
    case 'image/jpeg':
      return { ext: 'jpeg', type: 'image' } as const;
    case 'image/jpg':
      return { ext: 'jpg', type: 'image' } as const;
    case 'application/pdf':
      return { ext: 'pdf', type: 'pdf' } as const;
    case 'application/msword': // doc
      return { ext: 'doc', type: 'msdoc' } as const;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': //docx
      return { ext: 'docx', type: 'msdoc' } as const;
    case 'application/vnd.ms-powerpoint': //ppt
      return { ext: 'ppt', type: 'msdoc' } as const;
    case 'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation': //pptx
      return { ext: 'pptx', type: 'msdoc' } as const;
    case 'application/vnd.ms-excel': // xls
      return { ext: 'xls', type: 'msdoc' } as const;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': //xlsx
      return { ext: 'xlsx', type: 'msdoc' } as const;
    default:
      return { ext: undefined, type: 'unsupported' } as const;
  }
};

export type MediaFileInfo = Awaited<ReturnType<typeof getMediaFileInfo>>;
export type MediaFileType = MediaFileInfo['type'];
export type MediaFileExt = MediaFileInfo['ext'];
