import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import { useLockBodyScroll } from 'react-use';

export const PDFDocumentView: React.FC<{ url: string }> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const refScrollSection = useRef<HTMLDivElement | null>(null);
  useLockBodyScroll(true, refScrollSection);

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div ref={refScrollSection} className="overflow-auto h-full">
      <Document
        className="flex flex-col items-center"
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {numPages &&
          Array(numPages - 1)
            .fill(0)
            .map((_, index) => {
              return <Page width={1000} key={index} pageNumber={index + 1} />;
            })}
      </Document>
    </div>
  );
};
