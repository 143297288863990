import { useApolloClient, useMutation } from '@apollo/client';
import { useContext, useMemo, useEffect, useState, useLayoutEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TeacherEvaluationPageContext, activeSubmissionSelector } from '../../context';
import Select, { Option, SelectProps } from 'rc-select';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { set, sum, isEqual, cloneDeep, isEmpty } from 'lodash';
import {
  GetTeacherEvaluationPageDocument,
  GetTeacherEvaluationPageQuery,
  SubmissionCreditsInput,
  SubmissionRubricCredits,
  SubmissionStatus,
  SubmitAssignmentCreditsDocument,
  TeacherEvaluationPageSubmissionFragmentDoc,
  UpdateSubmissionStatusDocument,
} from '../../../../services/graphql/types/graphql';
import { useStoreContext } from '../../../../factorys/useStoreContext';
import { IdentityContext } from '../../../App/identity.context';
import { getFragmentData } from '../../../../services/graphql/types';
import { Transcript } from '../../../Transcript';
import StarSVG from '@Assets/star.svg?react';
import { CaretDownOutlined } from '@ant-design/icons';

export const GradeNumberSelect = ({
  value,
  readOnly = false,
  onChange,
}: {
  value: number;
  readOnly?: boolean;
  onChange: (value: number) => void;
}) => {
  return (
    <div className="flex space-x-3">
      {[0, 1, 2, 3, 4, 5].map((i) => {
        return (
          <div
            className={cx(
              'expand-clickable-area-5px',
              readOnly ? 'cursor-not-allowed' : 'cursor-pointer',
              { 'text-green-500': i === value },
            )}
            key={`extra-comment-credit-${i}`}
            onClick={() => (!readOnly ? onChange(i) : () => void 0)}
          >
            {i}
          </div>
        );
      })}
    </div>
  );
};

export const RubricInput: React.FC<{
  handleRubricLevelChange?: any;
  handleRubricCommentChange?: any;
  isReadOnly: boolean;
  assignmentRubric: GetTeacherEvaluationPageQuery['assignment']['assignmentRubrics'][0];
  rubricLevelValue: string;
  rubricComment: string;
}> = ({
  handleRubricCommentChange,
  assignmentRubric,
  isReadOnly,
  handleRubricLevelChange,
  rubricLevelValue,
  rubricComment,
}) => {
  const numStar = useMemo(() => {
    const index = assignmentRubric.rubric.rubricLevels.findIndex(
      (i) => i.rubricLevelId === rubricLevelValue,
    );
    return index;
  }, [assignmentRubric, rubricLevelValue]);
  // useEffect(() => console.info('numStar', numStar), [numStar]);

  const handleClickStar = (i: number) => {
    if (isReadOnly) {
      return;
    }
    handleRubricLevelChange(assignmentRubric.rubric.rubricId)(
      assignmentRubric.rubric.rubricLevels[i].rubricLevelId,
    );
  };

  return (
    <div key={`${JSON.stringify(assignmentRubric)}`}>
      <div className="text-[16px] leading-[50px] flex items-center justify-between w-full">
        <div className="flex-shrink-0 mr-4">{assignmentRubric.rubric.name}</div>
        <Select
          suffixIcon={() => <CaretDownOutlined />}
          disabled={isReadOnly}
          className="rubric-selection-box"
          onChange={handleRubricLevelChange(assignmentRubric.rubric.rubricId)}
          value={rubricLevelValue}
        >
          {assignmentRubric.rubric.rubricLevels
            .toSorted((a, b) => b.grade - a.grade)
            .map((rl) => {
              return (
                <Option key={rl.rubricLevelId}>
                  {rl.text} ({rl.grade}%)
                </Option>
              );
            })}
        </Select>
        {/* {assignmentRubric.isManualTyping && (
          <div className="flex">
            <div className="w-6 h-6" onClick={() => handleClickStar(0)}>
              <StarSVG></StarSVG>
            </div>
            <div className="w-6 h-6" onClick={() => handleClickStar(0)}>
              <StarSVG></StarSVG>
            </div>
            {Array(3)
              .fill(0)
              .map((_, i) => {
                return (
                  <div
                    key={`rubric-star-${i}-${assignmentRubric.rubric.rubricId}`}
                    className="w-6 h-6"
                    onClick={() => handleClickStar(i + 1)}
                  >
                    <StarSVG fill={i < numStar ? 'gold' : 'none'}></StarSVG>
                  </div>
                );
              })}
          </div>
        )} */}
      </div>
      <div className="bg-transparent text-white text-[14px] leading-[20px] w-[100%] py-[10px] border-x-0 border-y border-white border-dashed carret-white">
        <div className="w-full flex">
          <label className="flex-shrink-0">额外评价：</label>
          <textarea
            className="caret-white bg-inherit text-white w-full"
            disabled={isReadOnly}
            value={rubricComment}
            onChange={handleRubricCommentChange(assignmentRubric.rubric.rubricId)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export const CommentEditor = ({ artworkId }: { artworkId: string }): JSX.Element => {
  const apolloClient = useApolloClient();
  const { state } = useContext(TeacherEvaluationPageContext);

  const { currentIdentity, currentClass } = useStoreContext(IdentityContext);
  const role = currentIdentity?.identity;

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [retractDisabled, setRetractDisabled] = useState(false);
  const [revertDisabled, setRevertDisabled] = useState(false);

  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [showRetractConfirm, setShowRetractConfirm] = useState(false);
  const [showRevertConfirm, setShowRevertConfirm] = useState(false);

  const activeSubmission = useMemo(
    () => activeSubmissionSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );
  const isReadOnly = useMemo(() => {
    if (role === 'teacher') {
      return [SubmissionStatus.Completed, SubmissionStatus.CompletedButNoGrade].includes(
        activeSubmission.submissionStatus,
      );
    }
    if (role === 'ta') {
      return [
        SubmissionStatus.TaApproved,
        SubmissionStatus.Completed,
        SubmissionStatus.CompletedButNoGrade,
      ].includes(activeSubmission.submissionStatus);
    }
    return true;
  }, [activeSubmission.submissionStatus, role]);

  const extraCredit = useMemo(() => activeSubmission.gradeOffset ?? 0, [activeSubmission]);
  const [extraCreditCustom, setExtraCreditCustom] = useState<number | null>(null);
  const extraComment = useMemo(() => activeSubmission.extraComment ?? '', [activeSubmission]);
  const [extraCommentCustom, setExtraCommentCustom] = useState<string | null>(null);
  useEffect(() => {
    setExtraCreditCustom(null);
    setExtraCommentCustom(null);
  }, [activeSubmission]);

  const assignmentRubrics = useMemo(
    () =>
      state.assignmentData?.assignment.assignmentRubrics.toSorted(
        (a, b) => a.priority - b.priority,
      ) ?? [],
    [state],
  );
  // useEffect(() => console.info('assignmentRubrics', assignmentRubrics), [assignmentRubrics]);

  const [submissionCredits, setSubmissionCredits] = useState<
    Record<string, SubmissionRubricCredits | null>
  >({});
  useEffect(() => {
    console.info(
      `SubmissionID: ${activeSubmission.submissionId} status: ${activeSubmission.submissionStatus}`,
    );
    const credits: Record<string, SubmissionRubricCredits> = {};
    activeSubmission.credits.forEach((credit) => {
      if (credit.rubricLevel) {
        credits[credit.rubricId] = {
          rubricId: credit.rubricId,
          comment: credit.comment,
          rubricLevelId: credit.rubricLevel.rubricLevelId,
        };
      }
    });
    setSubmissionCredits(credits);
  }, [activeSubmission]);

  const originalSubmissionCredits = useMemo<SubmissionCreditsInput>(() => {
    const credits: Record<string, SubmissionRubricCredits | null> = {};
    activeSubmission.credits.forEach((credit) => {
      if (credit.rubricLevel) {
        credits[credit.rubricId] = {
          rubricId: credit.rubricId,
          rubricLevelId: credit.rubricLevel.rubricLevelId,
          comment: credit.comment,
        };
      }
    });

    const submitData: SubmissionCreditsInput = {
      submissionId: activeSubmission.submissionId,
      extra: {
        extraComments: activeSubmission.extraComment,
        extraCredits: activeSubmission.gradeOffset,
      },
      rubrics: Object.entries(credits).map(([rubricId, rubric]) => {
        return {
          rubricId,
          rubricLevelId: rubric?.rubricLevelId ?? null,
          comment: rubric?.comment ?? null,
        };
      }),
    };

    return submitData;
  }, [activeSubmission]);

  const pendingSubmissionCredits = useMemo<SubmissionCreditsInput>(() => {
    const submitData: SubmissionCreditsInput = {
      submissionId: activeSubmission.submissionId,
      extra: {
        extraComments: extraCommentCustom ?? activeSubmission.extraComment,
        extraCredits: extraCreditCustom ?? activeSubmission.gradeOffset,
      },
      rubrics: isEmpty(submissionCredits)
        ? originalSubmissionCredits.rubrics
        : Object.entries(submissionCredits).map(([rubricId, rubric]) => {
            return {
              rubricId,
              rubricLevelId: rubric?.rubricLevelId ?? '',
              comment: rubric?.comment ?? '',
            };
          }),
    };

    return submitData;
  }, [
    activeSubmission.extraComment,
    activeSubmission.gradeOffset,
    activeSubmission.submissionId,
    extraCommentCustom,
    extraCreditCustom,
    originalSubmissionCredits.rubrics,
    submissionCredits,
  ]);

  const isPendingSave = useMemo(
    () => !isEqual(pendingSubmissionCredits, originalSubmissionCredits),
    [originalSubmissionCredits, pendingSubmissionCredits],
  );

  // useEffect(
  //   () => console.info('originalSubmissionCredits', originalSubmissionCredits),
  //   [originalSubmissionCredits],
  // );
  // useEffect(
  //   () => console.info('pendingSubmissionCredits', pendingSubmissionCredits),
  //   [pendingSubmissionCredits],
  // );
  // useEffect(() => console.info('isPendingSave', isPendingSave), [isPendingSave]);

  const [submitSubmissionCredits] = useMutation(SubmitAssignmentCreditsDocument);
  const [updateSubmissionStatus] = useMutation(UpdateSubmissionStatusDocument);

  useEffect(() => {
    if (role === 'ta') {
      if (
        [
          SubmissionStatus.TaApproved,
          SubmissionStatus.Completed,
          SubmissionStatus.CompletedButNoGrade,
        ].includes(activeSubmission.submissionStatus)
      ) {
        setSubmitDisabled(true);
        setSaveDisabled(true);
      } else {
        setSubmitDisabled(false);
        setSaveDisabled(!isPendingSave);
      }

      return;
    }

    if (role === 'teacher') {
      if (
        [SubmissionStatus.Completed, SubmissionStatus.CompletedButNoGrade].includes(
          activeSubmission.submissionStatus,
        )
      ) {
        setSubmitDisabled(true);
        setSaveDisabled(true);
      } else {
        setSubmitDisabled(false);
        setSaveDisabled(!isPendingSave);
      }

      return;
    }
  }, [activeSubmission, isPendingSave, role]);

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const action = async () => {
      setSubmitDisabled(true);
      let submissionStatus: SubmissionStatus;
      if (role === 'ta') {
        submissionStatus = SubmissionStatus.TaApproved;
      } else if (role === 'teacher') {
        submissionStatus = SubmissionStatus.Completed;
      } else {
        return;
      }

      await updateSubmissionStatus({
        variables: {
          submissionId: activeSubmission.submissionId,
          submissionStatus,
        },
      });
      setExtraCommentCustom(null);
      setExtraCreditCustom(null);
      setSubmissionCredits({});
    };

    void toast
      .promise(action, {
        pending: '正在提交',
        success: '已提交',
        error: '发生错误，请重试',
      })
      .finally(() => setShowSubmitConfirm(false));
  };

  const onRevert: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const action = async () => {
      setRevertDisabled(true);

      await updateSubmissionStatus({
        variables: {
          submissionId: activeSubmission.submissionId,
          submissionStatus: SubmissionStatus.Submitted,
        },
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // setExtraCommentCustom(null);
      // setExtraCreditCustom(null);
      // setSubmissionCredits({});
    };

    void toast
      .promise(action, {
        pending: '正在撤回成绩',
        success: '已撤回成绩',
        error: '发生错误，请重试',
      })
      .finally(() => setShowRevertConfirm(false));
  };

  const onRetract: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const action = async () => {
      setRetractDisabled(true);

      await updateSubmissionStatus({
        variables: {
          submissionId: activeSubmission.submissionId,
          submissionStatus: SubmissionStatus.Retracted,
        },
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setExtraCommentCustom(null);
      setExtraCreditCustom(null);
      setSubmissionCredits({});
    };

    void toast
      .promise(action, {
        pending: '正在打回',
        success: '已打回',
        error: '发生错误，请重试',
      })
      .finally(() => setShowRetractConfirm(false));
  };

  const onSave: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const action = async () => {
      setSaveDisabled(true);
      await submitSubmissionCredits({
        variables: {
          input: pendingSubmissionCredits,
        },
      });
      setExtraCommentCustom(null);
      setExtraCreditCustom(null);
      setSubmissionCredits({});
    };

    void toast
      .promise(action, {
        pending: '正在保存',
        success: '已保存',
        error: '发生错误，请重试',
      })
      .catch((e) => {
        console.error(e);
        setSaveDisabled(false);
      });
  };

  const previewGrade = useMemo(() => {
    const grades = Object.entries(submissionCredits).map(([rubricId, rubric]) => {
      const assignmentRubric = assignmentRubrics.find((ar) => ar.rubric?.rubricId === rubricId);
      if (!assignmentRubric) {
        return 0;
      }
      const rubricLevel = assignmentRubric?.rubric?.rubricLevels.find(
        (rl) => rl.rubricLevelId === rubric?.rubricLevelId,
      );
      if (!rubricLevel) {
        return 0;
      }
      return rubricLevel.grade * assignmentRubric.weight;
    });

    return sum(grades) + (extraCreditCustom ?? extraCredit);
  }, [submissionCredits, extraCreditCustom, extraCredit, assignmentRubrics]);

  const handleRubricLevelChange = (rubricId: string) => (rubricLevelId: string) => {
    setSubmissionCredits((prev) => {
      return {
        ...prev,
        [rubricId]: { rubricId, rubricLevelId, comment: prev[rubricId]?.comment ?? '' },
      };
    });
  };

  const handleRubricCommentChange =
    (rubricId: string): React.ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setSubmissionCredits((prev) => {
        return {
          ...prev,
          [rubricId]: {
            rubricId,
            rubricLevelId: prev[rubricId]?.rubricLevelId ?? '',
            comment: e.target?.value ?? '',
          },
        };
      });
    };

  const toggleRubricLevel = (rubricId: string) => () => {
    if (isReadOnly) {
      return;
    }

    const rubricLevels = assignmentRubrics.find((ar) => ar.rubric?.rubricId === rubricId)?.rubric
      ?.rubricLevels;

    if (!rubricLevels) {
      return;
    }

    // console.info(submissionCredits[rubricId], rubricLevels[0].rubricLevelId);

    if (submissionCredits[rubricId]) {
      setSubmissionCredits((prev) => {
        return {
          ...prev,
          [rubricId]: null,
        };
      });
    } else {
      setSubmissionCredits((prev) => {
        return {
          ...prev,
          [rubricId]: { rubricLevelId: rubricLevels[0].rubricLevelId, rubricId, comment: '' },
        };
      });
    }
  };

  return (
    <div className="grade-comment-editor__container text-white">
      <div className="flex justify-between px-6 py-6">
        {assignmentRubrics
          .filter((ar) => ar.rubric.graderRoleType === 'TA')
          .map((r) => {
            return (
              <div
                className={cx(
                  'flex items-center justify-center text-center w-16 h-16 rounded-full text-black',
                  isReadOnly ? 'cursor-not-allowed' : 'cursor-pointer',
                  submissionCredits[r.rubric.rubricId] ? 'bg-[#05ED1C]' : 'bg-white',
                )}
                key={`${JSON.stringify(r)}`}
                onClick={toggleRubricLevel(r.rubric.rubricId)}
              >
                {r.rubric.name}
              </div>
            );
          })}
      </div>
      {role === 'teacher' && (
        <div className="flex flex-col">
          <header className="text-[14px] leading-[20px] tracking-[0.1em] opacity-60 ">
            知识点
          </header>
          <div className="flex flex-col">
            <div className="flex flex-col border-y">
              {assignmentRubrics
                .filter((ar) => ar.rubric.graderRoleType === 'Teacher')
                .map((r) => {
                  return (
                    <RubricInput
                      key={`rubric-input-${r.rubric.rubricId}`}
                      handleRubricCommentChange={handleRubricCommentChange}
                      assignmentRubric={r}
                      isReadOnly={isReadOnly}
                      handleRubricLevelChange={handleRubricLevelChange}
                      rubricLevelValue={submissionCredits[r.rubric.rubricId]?.rubricLevelId ?? ''}
                      rubricComment={submissionCredits[r.rubric.rubricId]?.comment ?? ''}
                    ></RubricInput>
                  );
                })}
              <div className="text-[16px] leading-[50px] flex justify-between mr-4">
                <span>
                  <label htmlFor="extra-comment">特别加分</label>
                </span>
                <GradeNumberSelect
                  readOnly={isReadOnly}
                  value={extraCreditCustom ?? extraCredit}
                  onChange={setExtraCreditCustom}
                ></GradeNumberSelect>
              </div>
              <div className="border-x-0 border-y border-white border-dashed py-[10px] flex">
                <div className="flex-shrink-0">整体评价：</div>
                <textarea
                  id="extra-comment"
                  disabled={isReadOnly}
                  className="bg-transparent text-white text-[14px] leading-[20px] w-[100%] carret-white"
                  rows={2}
                  draggable={false}
                  value={extraCommentCustom ?? extraComment}
                  onChange={(e) => setExtraCommentCustom(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center mt-[16px]">
        <div className="text-4xl flex-1 text-right pr-5">~{Math.ceil(previewGrade)}</div>
        <div className="text-black text-[20px] flex space-x-2">
          {role === 'teacher' &&
            activeSubmission.submissionStatus === SubmissionStatus.Completed && (
              <button
                type="button"
                disabled={activeSubmission.submissionStatus !== SubmissionStatus.Completed}
                className="flex items-center justify-center bg-[#f0403c] w-[60px] h-[60px] rounded-[50%] disabled:bg-[#c5c5c5]"
                onClick={() => setShowRevertConfirm(true)}
              >
                撤回
              </button>
            )}
          {role === 'ta' && (
            <button
              type="button"
              disabled={activeSubmission.submissionStatus !== SubmissionStatus.Submitted}
              className="flex items-center justify-center bg-[#f0403c] w-[60px] h-[60px] rounded-[50%] disabled:bg-[#c5c5c5]"
              onClick={() => setShowRetractConfirm(true)}
            >
              打回
            </button>
          )}
          <button
            type="button"
            disabled={saveDisabled}
            className="flex items-center justify-center bg-[#e8ed05] w-[60px] h-[60px] rounded-[50%] disabled:bg-[#c5c5c5]"
            onClick={onSave}
          >
            保存
          </button>
          <button
            type="button"
            disabled={submitDisabled || isPendingSave}
            className="flex items-center justify-center bg-[#05ED1C] w-[60px] h-[60px] rounded-[50%] disabled:bg-[#c5c5c5]"
            onClick={() => setShowSubmitConfirm(true)}
          >
            提交
          </button>
        </div>
      </div>
      {showRevertConfirm && currentIdentity?.identity === 'teacher' && (
        <div className="fullscreen-overlay z-[1000] flex justify-center items-center">
          <div className="z-[1001] relative flex flex-col items-center justify-center bg-white w-96">
            <div className="text-black text-5xl py-16">确认撤回成绩</div>
            <div className=" text-black text-3xl flex space-x-8 mt-auto mb-8 ">
              <button
                onClick={() => setShowRevertConfirm(false)}
                className=" flex items-center justify-center w-20 h-20 bg-[#e8ed05]  rounded-full"
                type="button"
              >
                返回
              </button>
              <button
                onClick={onRevert}
                className=" flex items-center justify-center w-20 h-20 bg-[#05ED1C]  rounded-full"
                type="button"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      )}
      {showSubmitConfirm && currentIdentity?.identity === 'teacher' && (
        <div className="fullscreen-overlay z-[1000] ">
          <div className="z-[1001] relative flex justify-center">
            <Transcript submissionId={activeSubmission.submissionId}></Transcript>
            <div className=" text-black text-3xl flex flex-col space-y-5 mt-auto mb-8 ml-16">
              <button
                onClick={() => setShowSubmitConfirm(false)}
                className=" flex items-center justify-center w-[100px] h-[100px] bg-[#e8ed05]  rounded-full"
                type="button"
              >
                返回
              </button>
              <button
                onClick={onSubmit}
                className=" flex items-center justify-center w-[100px] h-[100px] bg-[#05ED1C]  rounded-full"
                type="button"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      )}
      {showSubmitConfirm && currentIdentity?.identity === 'ta' && (
        <div className="fullscreen-overlay z-[1000] flex justify-center items-center">
          <div className="z-[1001] relative flex flex-col items-center justify-center bg-white w-96">
            <div className="text-black text-5xl py-16">确认提交</div>
            <div className=" text-black text-3xl flex space-x-8 mt-auto mb-8 ">
              <button
                onClick={() => setShowSubmitConfirm(false)}
                className=" flex items-center justify-center w-20 h-20 bg-[#e8ed05]  rounded-full"
                type="button"
              >
                返回
              </button>
              <button
                onClick={onSubmit}
                className=" flex items-center justify-center w-20 h-20 bg-[#05ED1C]  rounded-full"
                type="button"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      )}
      {showRetractConfirm && currentIdentity?.identity === 'ta' && (
        <div className="fullscreen-overlay z-[1000] flex justify-center items-center">
          <div className="z-[1001] relative flex flex-col items-center justify-center bg-white w-96">
            <div className="text-black text-5xl py-16">确认打回</div>
            <div className=" text-black text-3xl flex space-x-8 mt-auto mb-8 ">
              <button
                onClick={() => setShowRetractConfirm(false)}
                className=" flex items-center justify-center w-20 h-20 bg-[#e8ed05] rounded-full"
                type="button"
              >
                返回
              </button>
              <button
                onClick={onRetract}
                className=" flex items-center justify-center w-20 h-20 bg-[#05ED1C] rounded-full"
                type="button"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
