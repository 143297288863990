interface OSSImageProcessingOptions {
  resize?: {
    // 指定缩放的模式。
    m?:
      | 'lfit' // （默认值）：等比缩放，缩放图限制为指定w与h的矩形内的最大图片。
      | 'mfit' // 等比缩放，缩放图为延伸出指定w与h的矩形框外的最小图片。
      | 'fill' // 将原图等比缩放为延伸出指定w与h的矩形框外的最小图片，之后将超出的部分进行居中裁剪。
      | 'pad' // 将原图缩放为指定w与h的矩形内的最大图片，之后使用指定颜色居中填充空白部分。
      | 'fixed'; // 固定宽高，强制缩放。

    //指定目标缩放图的宽度。
    w?: number; // [1,4096]
    // 指定目标缩放图的高度。
    h?: number; // [1,4096]

    /**
     * 说明:
     * 长边是指原尺寸与目标尺寸的比值大的那条边；短边是指原尺寸与目标尺寸的比值小的那条边。
     * 例如原图为400 px*200 px，缩放为800 px*100 px。由于（400/800）<（200/100），所以在这个缩放操作中，200那条是长边，400那条是短边。
     */
    // 指定目标缩放图的最长边。
    l?: number; // [1,4096]
    // 指定目标缩放图的最短边。
    s?: number; // [1,4096]

    // 指定当目标缩放图大于原图时是否进行缩放。
    limit?: 0 | 1; // 1（默认值）：表示不按指定参数进行缩放，直接返回原图。0：按指定参数进行缩放。

    // 当缩放模式选择为pad（缩放填充）时，可以设置填充的颜色。
    color?: string; // RGB颜色值，例如：000000表示黑色，FFFFFF表示白色。 默认值：FFFFFF（白色）
  };
}

export const ossImage = (
  url: string | null | undefined,
  options: OSSImageProcessingOptions = {},
) => {
  if (!url) {
    return '';
  }

  const urlObj = new URL(url);
  urlObj.protocol = 'https';
  const param = urlObj.searchParams;

  let imageParams = 'image';
  if (options.resize) {
    imageParams += '/resize';
    options.resize.m && (imageParams += `,m_${options.resize.m}`);
    options.resize.w && (imageParams += `,w_${options.resize.w}`);
    options.resize.h && (imageParams += `,h_${options.resize.h}`);
    options.resize.l && (imageParams += `,l_${options.resize.l}`);
    options.resize.s && (imageParams += `,s_${options.resize.s}`);
    options.resize.limit && (imageParams += `,limit_${options.resize.limit}`);
    options.resize.color && (imageParams += `,color_${options.resize.color}`);
  }

  param.append('x-oss-process', imageParams);
  return urlObj.toString();
};
