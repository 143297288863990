import { useMemo, useRef, useState } from 'react';
import { useStoreContext } from '../../../../factorys/useStoreContext';
import { HomePageStoreContext } from '../../store';
import { AssignmentItem } from '../AssignmentItem';
import {
  HomePageLessonAssignmentFragmentDoc,
  HomePageCourseLessonFragmentDoc,
} from '../../../../services/graphql/types/graphql';

import { FragmentType, getFragmentData } from '../../../../services/graphql/types';
import _ from 'lodash';

export const LessonScrollView = ({
  data,
}: {
  data: FragmentType<typeof HomePageCourseLessonFragmentDoc>;
}): JSX.Element => {
  const {
    memos: { homePageData },
  } = useStoreContext(HomePageStoreContext);

  const lesson = getFragmentData(HomePageCourseLessonFragmentDoc, data);

  const currentSchool = useMemo(() => homePageData.school, [homePageData]);
  const currentClass = useMemo(() => homePageData.class, [homePageData]);

  const hViewportContainer = useRef<HTMLDivElement>(null);
  const hScrollContainer = useRef<HTMLDivElement>(null);

  const [dragging, setDragging] = useState<boolean>(false);
  const [movingStart, setMovingStart] = useState(0);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (hScrollContainer.current) {
      setDragging(true);
      setMovingStart(e.clientX + hScrollContainer.current?.scrollLeft);
    }
  };
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const dDragging = e.clientX - movingStart;
    if (dragging && hScrollContainer.current) {
      hScrollContainer.current.scrollLeft = -dDragging;
    }
  };
  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const dDragging = e.clientX - movingStart;
    // console.info('moving end', dDragging);

    setDragging(false);
    setMovingStart(0);
  };

  if (!currentSchool || !currentClass) {
    return <div></div>;
  }

  return (
    <div ref={hViewportContainer} className="border-b border-[#c4c4c4] pb-11">
      <div className="flex items-center h-11 pl-12 font-bold text-xl border-b border-[#c4c4c4]">
        {lesson.name}
      </div>
      <div
        ref={hScrollContainer}
        className="flex pt-12 overflow-auto"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
      >
        {lesson?.assignments?.map((assignment) => {
          const assignmentId = getFragmentData(
            HomePageLessonAssignmentFragmentDoc,
            assignment,
          ).assignmentId;

          return (
            <AssignmentItem
              dragging={dragging}
              key={assignmentId}
              data={assignment}
              classId={currentClass.classId}
              courseId={lesson.courseId}
            />
          );
        })}
        <div className="w-[100px] flex-shrink-0"></div>
      </div>
    </div>
  );
};
