import { useContext, useEffect, useMemo, useState } from 'react';
import { ossImage } from '../../../../utils/ossImage';
import {
  TeacherEvaluationPageContext,
  filteredStudentsSelector,
  // studentGradesSelector,
  TeacherEvaluationPageActions as actions,
} from '../../context';

import cx from 'classnames';

import { getMediaFileInfo } from '../../../../utils/media';
import { useStoreContext } from '../../../../factorys/useStoreContext';
import { IdentityContext } from '../../../App/identity.context';

const PreviewImage = ({ url, className }: { url: string; className: string }) => {
  const [ext, setExt] = useState<undefined | string>(undefined);
  useEffect(() => {
    if (url) {
      getMediaFileInfo(url)
        .then(({ ext }) => {
          setExt(ext);
        })
        .catch(console.error);
    }
  }, [url]);

  if (!ext) {
    return <div></div>;
  }

  if (ext === 'pdf') {
    return (
      <div className="text-m text-red-500 absolute left-1/2 top-2/3 -translate-x-1/2 -translate-y-1/2">
        PDF
      </div>
    );
  }

  return (
    <img
      className={className}
      src={ossImage(url, { resize: { m: 'fill', w: 150, h: 150 } })}
      alt=""
    />
  );
};

export const StudentSelector = () => {
  const {
    state,
    state: { activeStudentIndex },
    dispatch,
  } = useContext(TeacherEvaluationPageContext);
  const { currentIdentity } = useStoreContext(IdentityContext);
  const filteredStudent = useMemo(
    () => filteredStudentsSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );
  // const studentGrades = useMemo(() => studentGradesSelector(state), [state]);

  return (
    <div className="border-l border-r space-y-[5px] flex flex-col px-[5px] overflow-y-auto">
      {filteredStudent.map((s, iStudent: number) => {
        return (
          <div
            className={cx('w-[110px] h-[110px] bg-opacity-50 bg-black relative flex-shrink-0')}
            key={`student-list-${s.user.userId}`}
            onClick={() => dispatch(actions.setActiveStudentIndex(iStudent))}
          >
            <PreviewImage
              className={cx(
                'brightness-[0.3]',
                iStudent === activeStudentIndex && '!brightness-100',
              )}
              url={s.submissions?.[0]?.artworks[0]?.medias?.[0]?.url.replace('http://', 'https://')}
            />
            <div className="absolute left-0 top-0 w-full text-white opacity-70 p-1 pb-8 bg-[linear-gradient(180deg,#1a1a1a_35%,rgba(0,0,0,0.00)_100%);]">
              <div className="text-xs">{s.studentNumber}</div>
              <div className="text-base">{s.user.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
