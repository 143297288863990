import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import React, { useMemo } from 'react';
import { apiClient } from '../../services/api/client';

import MuyitongxueLogoBlack from '@Assets/muyitongxue-logo--black.png';
import { Loader } from '../../components/Loader';

import SigChenqi from './assets/sig--chenqi.jpg';
import SigJiangyan from './assets/sig--jiangyan.jpg';

export const PersonalCourseConcludePage = () => {
  const { error, data, isLoading } = useQuery({
    queryKey: ['personalCourseConclude'],
    queryFn: async () => {
      const res = await apiClient.get('/user/semester-concluded');
      return res.data;
    },
  });

  const classAssignmentCount = useMemo(() => {
    return data?.submissions.filter((s: any) => s.assignmentName.match(/课堂/g)).length;
  }, [data]);

  const homeAssignmentCount = useMemo(() => {
    return data?.submissions.filter((s: any) => s.assignmentName.match(/家庭/g)).length;
  }, [data]);

  const submissionWithComment = useMemo(() => {
    return data?.submissions.filter((s: any) => s.extraComment);
  }, [data]);

  const fiveStarCount = useMemo(() => {
    const count = data?.submissions
      .map((s: any) => s.credits)
      .flat()
      .filter((c: any) => c.levelGrade === 90 && c.type === 'Teacher').length;
    return count;
  }, [data]);

  if (isLoading || error || !data) {
    return <Loader fullscreen />;
  }

  //   亲爱的       同学，

  // 这一学期，你完成了“感知色彩”、“中华之花”、“触摸肌理”、“作出肌理”
  // 四个单元的学习，共 16 课，24 学时。
  // 要求完成 16 个实践任务的共XX张作业（其中课堂作业XX张，家庭作业XX张）。

  // XX同学共参与课程XX次，完成了XX学时的学习，
  // 完成了XX个实践任务的共XX张作业（其中课堂作业XX张，家庭作业XX张）
  // 其中获得5星评价XX次。

  // 获得特别鼓励和加分XX次。

  // 希望你再接再厉，努力做不一样的自己

  return (
    <div className="flex w-full h-full overflow-hidden">
      <div className="mx-auto overflow-auto w-[800px] bg-white shadow-2xl pt-16 pb-[120px]">
        <div className="flex flex-col justify-center items-center">
          <img className="w-[72px] h-[72px]" src={MuyitongxueLogoBlack} alt="" />
          <div className="mt-11 text-[#797979] text-2xl tracking-[2.4px] text-center w-[325px] pb-1 border-b border-b-[#F0403C]">
            小学阶段 <br></br>视觉艺术基础素养
          </div>
          <div className="mt-2 text-[32px] tracking-[3.2px]">24.3-24.7学期报告</div>
          <div className="mt-1 text-[20px] font-medium tracking-[2px] text-[#9a9a9a]">
            {' '}
            2024。7.6
          </div>
        </div>
        <div className="pl-[150px] pr-[72px] leading-[24px] text-[14px] mt-9">
          <p>
            亲爱的<span className="mx-2 text-[22px]">{data.name}</span>同学，
          </p>
          <br />
          <p>
            这一学期，你完成了“感知色彩”、“中华之花”、“触摸肌理”、“作出肌理”四个单元的学习，共{' '}
            <span className="text-[22px]">16</span> 课，共 <span className="text-[22px]">24</span>{' '}
            学时， <br></br>
            要求完成 <span className="text-[22px]">16</span> 个实践任务的共{' '}
            <span className="text-[22px]">43</span> 张作业（其中课堂作业{' '}
            <span className="text-[22px]">24</span> 张，家庭作业{' '}
            <span className="text-[22px]">19</span> 张）。
          </p>
          <br />
          <p>
            {data.name}同学共参与课程 <span className="text-[22px]">{data.attendence}</span>{' '}
            次，完成了 <span className="text-[22px]">{+data.attendence * 1.5}</span> 学时的学习，
            <br></br>
            完成了 <span className="text-[22px]">{data.attendence}</span> 个实践任务的共{' '}
            <span className="text-[22px]">{data.submissions.length}</span> 张作业（其中课堂作业{' '}
            <span className="text-[22px]">{classAssignmentCount}</span> 张，家庭作业{' '}
            <span className="text-[22px]">{homeAssignmentCount}</span> 张）<br></br>
            其中获得5星评价 <span className="text-[22px]">{fiveStarCount}</span> 次。
          </p>
          <br />
          <p>
            获得特别鼓励和加分 <span className="text-[22px]">{submissionWithComment.length}</span>{' '}
            次。
          </p>
          <br />
          <div className="space-y-8">
            {submissionWithComment.map((s: any) => {
              return (
                <div>
                  <div className="flex" key={s.id}>
                    <div className="flex flex-wrap">
                      {s.urls.map((u: string) => {
                        return <img className="h-[200px] w-auto" src={u} alt="" />;
                      })}
                    </div>
                    {/* <div>
                      <p>{s.courseName}</p>
                      <p>{s.lessonName}</p>
                      <p>{s.assignmentName}</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <span className=" text-red-600">特别评价：</span>
                    {s.extraComment}
                  </div>
                </div>
              );
            })}
          </div>
          <br />
          <br />
          <p>
            希望你再接再厉，努力<span className="text-[22px]">做不一样的自己</span>
          </p>
          <br />
          <br />
          <br />
          <p>目异在线少年艺术学院任课教师：</p>
          <br />
          <br />
          <div className="flex">
            <div className="flex flex-col">
              <img className="h-[100px] w-auto" src={SigChenqi} alt="" />
              <p className="text-[10px] text-right">中央美术学院 副教授</p>
            </div>
            <div className="flex flex-col ml-24">
              <img className="h-[100px] w-auto" src={SigJiangyan} alt="" />
              <p className="text-[10px] text-right">中央美术学院 副教授</p>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};
