import { Context, useContext } from 'react';

export const useStoreContext = <C>(context: Context<C>) => {
  const c = useContext(context);
  if (!c) {
    throw new Error('Should call inside provider');
  }

  return c;
};
