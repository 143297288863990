import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

export const useQueryState = (key: string, initialValue: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  const setQueryStringValue = (key: string, value: string[], queryString = location.search) => {
    const values = qs.parse(queryString);
    const newQsValue = qs.stringify({ ...values, [key]: value });

    navigate(`${location.pathname}?${newQsValue}`, { replace: true });
  };

  const getQueryStringValue = (key: string, queryString = location.search) => {
    const values = qs.parse(queryString);
    return values[key];
  };

  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key],
  );

  useEffect(() => {
    const values = qs.parse(location.search);
    setValue(values[key] ?? initialValue);
  }, [location]);

  return [value, onSetValue] as [string, (p: string | string[]) => void];
};
