import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createContext, ReducerState, useContext, useEffect, useReducer } from 'react';
import qs from 'query-string';

import { IdentityContext } from '../App/identity.context';

import { useLazyQuery } from '@apollo/client';
import { AuthingContext } from '../App/authing.context';
import { useStoreContext } from '../../factorys/useStoreContext';

import { Loader } from '../../components/Loader';
import {
  GetHomePageDataDocument,
  GetHomePageDataQuery,
  HomePageCourseFragmentDoc,
} from '../../services/graphql/types/graphql';
import { getFragmentData } from '../../services/graphql/types';
import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';

const storageName = 'HomePageState';

export const defaultState = {
  userHomePageData: undefined as any,
};

const { actions, reducer } = createSlice({
  name: storageName,
  initialState: defaultState,
  reducers: {
    setUserHomePageData: (state, action) => {
      state.userHomePageData = action.payload;
    },
  },
});

export type HomePageStorageState = ReducerState<typeof reducer>;

export const HomePageStoreContext = createContext<
  | {
      state: HomePageStorageState;
      dispatch: React.Dispatch<React.ReducerAction<typeof reducer>>;
      memos: {
        homePageData: GetHomePageDataQuery;
      };
    }
  | undefined
>(undefined);

export const HomeStoreContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const { currentClassId, currentSchoolId, currentIdentity } = useStoreContext(IdentityContext);

  const { role } = useContext(AuthingContext);

  const [getHomePageData, { loading, error, data: homePageData }] =
    useLazyQuery(GetHomePageDataDocument);

  useEffect(() => {
    console.info('homePageData', homePageData);
  }, [homePageData]);

  useEffect(() => {
    if (currentIdentity?.identity === 'student') {
      navigate(`/course`);
    }
    if (currentIdentity?.identity === 'ta') {
      const query = qs.stringify({
        classId: currentIdentity.classId,
      });
      navigate(`/gradebook?${query}`);
    }
  }, [currentIdentity, homePageData, navigate, loading]);

  useEffect(() => {
    if (currentSchoolId && currentClassId && role) {
      getHomePageData({
        variables: {
          schoolId: currentSchoolId,
          classId: currentClassId,
        },
      }).catch(console.info);
    }
  }, [currentSchoolId, currentClassId, role, getHomePageData]);

  useEffect(() => {
    if (error) {
      console.error(error);
      Sentry.captureException(error);

      localStorage.removeItem('CurrentSchoolId');
      localStorage.removeItem('CurrentClassId');
      location.reload();
    }
  }, [error]);

  if (!homePageData || loading || !currentIdentity) {
    return <Loader fullscreen />;
  }

  return (
    <HomePageStoreContext.Provider
      value={{
        state,
        dispatch,
        memos: {
          homePageData,
        },
      }}
    >
      {children}
    </HomePageStoreContext.Provider>
  );
};

export const HomePageActions = actions;
