import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { getMediaFileInfo, MediaFileExt, MediaFileType } from '../../utils/media';
import { ossImage } from '../../utils/ossImage';

import PDFArrowLeftSVG from './assets/pdf-arrow-left.svg?react';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import Spinner from '../../assets/spinner.svg?react';

export const ArtworkMediaRender = ({
  url,
  thumbnail = false,
}: {
  url: string;
  thumbnail?: boolean;
}): JSX.Element => {
  const [mediaType, setMediaType] = useState<MediaFileType | undefined>(undefined);
  const [mediaExt, setMediaExt] = useState<MediaFileExt | undefined>(undefined);
  const [pdfHandler, setPdfHandler] = useState<undefined | Promise<PDFDocumentProxy>>(undefined);
  const [pdfPreview, setPdfPreview] = useState<undefined | string>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [pdfCurrentPage, setPdfCurrentPage] = useState<number>(1);
  const incPdfPage = () => {
    pdfHandler
      ?.then((pdf) => {
        const max = pdf.numPages;
        if (pdfCurrentPage < max) {
          setPdfCurrentPage((p) => p + 1);
        }
      })
      .catch(console.error);
  };
  const decPdfPage = () => {
    setPdfCurrentPage((p) => (p > 1 ? p - 1 : p));
  };

  const PDFPlugin = useMemo(async () => {
    // @ts-ignore
    const PDF = await import('pdfjs-dist/build/pdf.js');
    // @ts-ignore
    const pdfjsWorkerEntry = await import('pdfjs-dist/build/pdf.worker.entry');
    PDF.GlobalWorkerOptions.workerSrc = pdfjsWorkerEntry;
    return PDF;
  }, []);

  useAsync(async () => {
    if (!url) {
      return;
    }
    setLoading(true);
    // console.info(`[ArtworkMediaRender] rendering ${thumbnail ? 'thumbnail' : ''} url: ${url}`);

    const { ext, type } = await getMediaFileInfo(url);
    // console.info('[ArtworkMediaRender] file ext: ', ext);

    setMediaType(type);
    setMediaExt(ext);
    if (ext === 'pdf') {
      const PDF = await PDFPlugin;
      setPdfHandler(PDF.getDocument(url).promise);
      // console.info('[ArtworkMediaRender] pdfjs loaded');
    }

    setLoading(false);
  }, [url]);

  useAsync(async () => {
    try {
      if (mediaType === 'pdf' && pdfHandler) {
        const pdf = await pdfHandler;
        const page = await pdf.getPage(pdfCurrentPage);

        const scale = 1.5;
        const viewport = page.getViewport({ scale: scale });
        const outputScale = window.devicePixelRatio || 1;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          throw new Error('Can not get canvas context');
        }

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + 'px';
        canvas.style.height = Math.floor(viewport.height) + 'px';

        const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined;

        const renderContext = {
          canvasContext: context,
          transform: transform,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        const pdfDataUrl = canvas.toDataURL();
        setPdfPreview(pdfDataUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }, [mediaType, pdfCurrentPage]);

  if (!url || mediaType === undefined || isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Spinner className="w-[40px] h-[40px]"></Spinner>
      </div>
    );
  }

  if (mediaType === 'image') {
    return (
      <img
        className="w-full h-full object-contain object-center"
        src={ossImage(url, { resize: { w: 1200 } })}
        alt=""
      ></img>
    );
  }

  if (thumbnail) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        {mediaExt?.toUpperCase()}
      </div>
    );
  }

  if (mediaType === 'msdoc') {
    return (
      <iframe
        className="w-full h-full"
        id="msdoc-iframe"
        title="msdoc-iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
      ></iframe>
    );
  }

  if (mediaType === 'pdf' && pdfPreview) {
    return (
      <div className="relative w-full h-full overflow-hidden">
        <img className="w-full h-full object-contain object-center" src={pdfPreview} alt=""></img>
        <div className="flex items-center absolute bottom-[50px] left-1/2 -translate-x-1/2">
          <PDFArrowLeftSVG onClick={decPdfPage}></PDFArrowLeftSVG>
          <div className="text-2xl mx-5">{pdfCurrentPage}</div>
          <PDFArrowLeftSVG onClick={incPdfPage} className="rotate-180"></PDFArrowLeftSVG>
        </div>
      </div>
    );
  }

  return <div></div>;
};
