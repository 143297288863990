import React, { useMemo, useRef } from 'react';
import Div100vh from 'react-div-100vh';

import { useContext } from 'react';
import { AuthingContext } from '../App/authing.context';
import { Loader } from '../../components/Loader';
import { useStoreContext } from '../../factorys/useStoreContext';
import { HomePageStoreContext, HomeStoreContextProvider } from './store';
import IdentitySelector from '../../components/IdentitySelector';
import { CourseView } from './components/CourseView';
import { useLockBodyScroll } from 'react-use';
import { HomePageCourseFragmentDoc } from '../../services/graphql/types/graphql';
import { getFragmentData } from '../../services/graphql/types';

import MainPageBanner from './assets/main-page-banner.png';
import MainPageBannerThin from './assets/main-page-banner--thin--2024-2.png';
// import MainPageBannerThin from './assets/main-page-banner--thin--2024.png';
import XinYiShuKeLogo from './assets/xin-yi-shu-ke-logo.png';
import MuYiTongXueLogo from '../../assets/muyitongxue-logo--new.png';
import { useNavigate } from 'react-router-dom';
import { IdentityContext } from '../App/identity.context';

import BadgeBronze from '@Assets/badge-bronze.png';
import BadgeFullPresent from '@Assets/badge-full-present.png';
import Button from '@Assets/button.png';

const attendence = {
  '-1': 16,
  '02001': 16,
  '02002': 16,
  '02003': 14,
  '02004': 16,
  '02005': 16,
  '02006': 16,
  '02007': 15,
  '02008': 15,
  '02009': 14,
  '02010': 16,
  '02011': 12,
  '02012': 12,
  '02013': 16,
  '02015': 15,
  '02016': 15,
  '02017': 16,
  '02018': 16,
  '02019': 11,
  '02020': 15,
  '02021': 15,
  '02022': 16,
  '02023': 16,
  '02024': 16,
  '02025': 14,
  '02026': 16,
  '02027': 16,
  '02028': 15,
  '02029': 15,
  '02030': 14,
  '02031': 15,
  '02032': 16,
  '02033': 16,
  '02034': 15,
  '02035': 16,
  '02036': 16,
  '02037': 16,
  '02038': 16,
  '02039': 11,
  '02040': 6,
  '02041': 8,
  '01001': 2,
  '01002': 14,
  '01003': 16,
  '01004': 14,
  '01005': 15,
  '01006': 13,
  '01007': 16,
  '01008': 16,
  '01009': 15,
  '01010': 16,
  '01011': 14,
  '01012': 13,
  '01013': 16,
  '01014': 16,
  '01015': 12,
  '01016': 13,
  '01017': 15,
  '01018': 16,
  '01019': 15,
  '01020': 14,
  '01021': 7,
  '01022': 12,
  '01023': 16,
  '01024': 6,
} as Record<string, number>;

const PageContainer = () => {
  const navigate = useNavigate();
  const { user, role, logout } = useContext(AuthingContext);
  const { currentIdentity } = useStoreContext(IdentityContext);

  const isFullPresent = useMemo(() => {
    if (!currentIdentity) {
      return false;
    }

    return attendence[currentIdentity?.studentNumber.split('-')[1]] === 16;
  }, []);

  const {
    memos: { homePageData },
  } = useStoreContext(HomePageStoreContext);

  const refMainSection = useRef<HTMLDivElement | null>(null);
  useLockBodyScroll(true, refMainSection);

  if (!user || !role || !homePageData || !currentIdentity) {
    return <Loader fullscreen />;
  }

  return (
    <Div100vh className="flex flex-col">
      <header className="relative flex-shrink-0 w-full bg-[#F4F1EC] flex flex-col drop-shadow-[-2px_0px_8px_rgba(0,0,0,0.25)]">
        <div className="absolute top-[4.02vw] left-1/2 -translate-x-1/2 z-50 text-[#797979]">
          <IdentitySelector
            enableSchool={role === 'teacher'}
            enableClass
            // enableCourse={role === 'student'}
          ></IdentitySelector>
        </div>
        <div className="absolute top-[8.69vw] left-[18.68vw]">
          <div className="text-white h-[4.86vw] flex bg-[#A7A4A0] rounded-[2.43vw] items-center px-[0.833vw] py-[0.556vw] relative z-[1000]">
            {/* <img className="rounded-full w-[3.47vw] h-[3.47vw]" src={user?.photo ?? ''} alt="" /> */}
            <div className="flex space-x-[0.69vw]">
              <img className="rounded-full w-[3.47vw] h-[3.47vw]" src={BadgeBronze} alt="" />
              {isFullPresent && (
                <img className="rounded-full w-[3.47vw] h-[3.47vw]" src={BadgeFullPresent} alt="" />
              )}
            </div>
            <div className="flex flex-col ml-[0.69vw] font-semibold">
              <div className="text-[1.39vw]">{user?.nickname ?? user?.name}</div>
              <div className="text-[0.83vw]">青铜</div>
            </div>
            <div
              onClick={logout}
              className="text-[1.39vw] text-[#797979] ml-[1.389vw] rounded-full w-[3.47vw] h-[3.47vw] bg-white flex items-center justify-center"
            >
              退出
            </div>
          </div>
          {/* <div className="text-white h-[4.86vw] flex bg-transparent rounded-[2.43vw] items-center px-[0.833vw] py-[0.556vw] absolute top-0 right-[-5.36vw] w-full">
            <div className="ml-auto w-full h-full flex leading-tight space-x-[0.55vw] justify-center items-center">
              {role === 'student' && <a
                target="_blank"
                href="/semester-conclude"
                className="text-[#4F4F4F] text-[0.9vw] flex justify-center items-center ml-auto rounded-full w-[3.47vw] h-[3.47vw] relative"
              >
                <img className="w-full h-full absolute top-0 left-0" src={Button} alt="" />
                <div className="relative z-[1000] text-[#413F40]">
                  学期<br></br>总结
                </div>
              </a>}
            </div>
          </div> */}
        </div>
        <img
          className="absolute top-[5.42vw] left-[6.11vw] w-[9.375vw] h-[9.375vw]"
          src={MuYiTongXueLogo}
          alt=""
        />
        <img className="w-full" src={MainPageBannerThin} alt="" useMap="#bannerlinkmap"></img>
        <a href="/exhibition/73d7956d-724b-409a-be8b-c325ea41a3e1" title="">
          <div className="absolute top-0 left-[45%] right-0 bottom-0"></div>
        </a>
        {/* <div className="absolute top-[4.02vw] left-1/2 -translate-x-1/2 tracking-[0.86vw] font-semibold text-[1.389vw] text-[#797979]">
          目异在线少年视觉艺术学院
        </div> */}
      </header>
      {/* <aside className='bg-[#BCBCBC] w-[100px] flex-shrink-0 flex'>
        <div className='ml-[50px] h-full border-l-[1px] border-white'></div>
      </aside> */}

      <main className="z-10 flex-1 overflow-auto">
        <div
          ref={refMainSection}
          className="z-30 bg-[#a7a4a0] h-full w-full drop-shadow-[-1px_0px_4px_rgba(0,0,0,0.25)] overflow-auto"
        >
          {homePageData?.courses.map((course, i) => {
            const courseFrag = getFragmentData(HomePageCourseFragmentDoc, course);
            if (courseFrag.lessons.length === 0) {
              return;
            }
            return (
              <CourseView
                key={`HomePageCourseItem-${i}-${courseFrag.courseId}`}
                data={course}
              ></CourseView>
            );
          })}
          <div className="h-[100px] flex-shrink-0"></div>
        </div>
      </main>
    </Div100vh>
  );
};

export const HomePage = (): JSX.Element => {
  return (
    <HomeStoreContextProvider>
      <PageContainer></PageContainer>
    </HomeStoreContextProvider>
  );
};
