import Axios from 'axios';

export const apiClient = Axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: (() => {
    const token = localStorage.getItem('_authing_token');
    return {
      authorization: token ? `Bearer ${token}` : '',
    };
  })(),
});
