import { useContext, useEffect } from 'react';
import {
  TeacherEvaluationPageContext,
  TeacherEvaluationPageActions as actions,
} from '../../context';
import cx from 'classnames';
import { IdentityContext } from '../../../App/identity.context';
import { useStoreContext } from '../../../../factorys/useStoreContext';

export const StudentFilter = () => {
  const {
    state: { submissionFilter, activeGradeFilter, sortBy, showTranscriptPreview },
    dispatch,
  } = useContext(TeacherEvaluationPageContext);

  const { currentIdentity } = useStoreContext(IdentityContext);

  return (
    <div className="flex flex-col self-center font-medium mr-6 space-y-5">
      <div
        onClick={() => dispatch(actions.setShowTranscriptPreview(!showTranscriptPreview))}
        className={cx(
          'w-10 h-10 rounded-full bg-[#C4C4C4] flex items-center justify-center text-[10px] text-center',
          { 'bg-green-500': showTranscriptPreview },
        )}
      >
        成绩单<br></br>预览
      </div>
      <div className="border border-[#A6A6A6] flex flex-col bg-[#A6A6A6] w-10 rounded-full self-center overflow-hidden text-3 leading-[14px]">
        <div className="w-10 h-10 rounded-full bg-[#C4C4C4] flex items-center justify-center">
          <div className="w-3">显示</div>
        </div>
        <div className="mt-2 py-4 rounded-full border-x border-[#A6A6A6] h-[100%] bg-[#2B2B2C] flex flex-col items-center justify-center">
          <div className="w-full opacity-60 text-white flex flex-col items-center justify-center space-y-[20px]">
            <div
              className="flex flex-col justify-center items-center"
              onClick={() => dispatch(actions.setSubmissionFilter('all'))}
            >
              <div className="w-3">全部</div>
              <svg
                className="mt-[5px]"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={submissionFilter === 'all' ? '#FF0000' : '#C4C4C4'}
                />
              </svg>
            </div>
            <div
              className={cx('flex flex-col justify-center items-center')}
              onClick={() => dispatch(actions.setSubmissionFilter('pending'))}
            >
              <div className={cx('w-3', submissionFilter === 'pending' && 'text-red-500')}>
                待评价
              </div>
            </div>
            {currentIdentity?.identity === 'teacher' && (
              <div
                className="flex flex-col justify-center items-center"
                onClick={() => dispatch(actions.setSubmissionFilter('star'))}
              >
                <div className="w-3 ">星标</div>
                <svg
                  className="mt-[5px]"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0L9.98633 4.07786L14.6329 5.18237L11.523 8.80714L11.9084 13.5676L7.5 11.73L3.09161 13.5676L3.47703 8.80714L0.367076 5.18237L5.01367 4.07786L7.5 0Z"
                    fill={submissionFilter === 'star' ? '#FF0000' : '#C4C4C4'}
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {currentIdentity?.identity === 'teacher' && (
        <div className="border border-[#A6A6A6] flex flex-col bg-[#A6A6A6] w-10 rounded-full self-center overflow-hidden text-3 leading-[14px]">
          <div className="flex justify-center items-center">
            <div className="w-3 pt-[10px]">成绩</div>
          </div>
          <div className="relative mt-[5px] rounded-full w-full bg-[#2B2B2C] flex flex-col items-center justify-center">
            <div
              className={cx('student-order-method-button', {
                active: activeGradeFilter === 'all',
              })}
            >
              <div>全部</div>
            </div>
            <div
              className={cx('student-order-method-button', {
                active: activeGradeFilter === 'better',
              })}
            >
              <div>较好</div>
            </div>
            <div
              className={cx('student-order-method-button', {
                active: activeGradeFilter === 'normal',
              })}
            >
              <div>一般</div>
            </div>
            <div
              className={cx('student-order-method-button', {
                active: activeGradeFilter === 'weaker',
              })}
            >
              <div>较弱</div>
            </div>
          </div>
        </div>
      )} */}

      <div className="border border-[#A6A6A6] flex flex-col bg-[#A6A6A6] w-10 rounded-full self-center overflow-hidden text-3 leading-[14px]">
        <div className="flex justify-center items-center">
          <div className="w-3 pt-[10px]">排列</div>
        </div>
        <div className="relative mt-[5px] rounded-full w-full bg-[#2B2B2C] flex flex-col items-center justify-center">
          <div
            className={cx('student-order-method-button', {
              active: sortBy === 'studentNumber',
            })}
            onClick={() => dispatch(actions.setSortMethod('studentNumber'))}
          >
            <div>按学号</div>
          </div>
          <div
            className={cx('student-order-method-button', {
              active: sortBy === 'name',
            })}
            onClick={() => dispatch(actions.setSortMethod('name'))}
          >
            <div>按姓名</div>
          </div>
          <div
            className={cx('student-order-method-button', {
              active: sortBy === 'grade',
            })}
            onClick={() => dispatch(actions.setSortMethod('grade'))}
          >
            <div>按成绩</div>
          </div>
        </div>
      </div>
    </div>
  );
};
