import React, {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  ReducerAction,
  ReducerState,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import _, { cloneDeep, sum } from 'lodash';
import { Loader } from '../../components/Loader';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from '../App/identity.context';
import {
  AddArtworkTagsDocument,
  DeleteArtworkTagsDocument,
  GetTeacherEvaluationPageDocument,
  GetTeacherEvaluationPageQuery,
  Submission,
  SubmissionStatus,
  TeacherEvaluationPageSubmissionFragmentDoc,
  UserIdentity,
} from '../../services/graphql/types/graphql';
import { useLocalStorage } from 'react-use';
import { getFragmentData } from '../../services/graphql/types';

export interface AssignmentArtwork {
  assignmentId: string;
  [key: string]: any;
}

const initialState = {
  submissionFilter: 'all' as 'all' | 'pending' | 'star',
  showTranscriptPreview: false,
  activeStudentIndex: 0,
  activeArtworkIndex: 0,
  showPendingAction: false,
  activeGradeFilter: 'all' as 'all' | 'better' | 'normal' | 'weaker',
  sortBy: 'studentNumber',
  assignmentData: undefined as
    | undefined
    | (GetTeacherEvaluationPageQuery & {
        studentArtworks: (UserIdentity & { submissions: Submission[] })[];
      }),
};

type TeacherEvaluationPageState = typeof initialState;

const { actions, reducer } = createSlice({
  name: 'TeacherEvaluationPageState',
  initialState,
  reducers: {
    setSubmissionFilter: (state, action) => {
      state.submissionFilter = action.payload;
    },
    setActiveStudentIndex: (state, action) => {
      state.activeStudentIndex = action.payload;
    },
    setAssignmentData: (state, action) => {
      state.assignmentData = action.payload;
    },
    setShowTranscriptPreview: (state, action) => {
      state.showTranscriptPreview = action.payload;
    },
    setActiveGradeFilter: (state, action) => {
      state.activeGradeFilter = action.payload;
    },
    setSortMethod: (state, action) => {
      state.sortBy = action.payload;
    },
    setActiveArtwork: (state, action) => {
      state.activeArtworkIndex = action.payload;
    },
  },
});

const useTeacherEvaluationPageStore = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentIdentity } = useStoreContext(IdentityContext);

  return {
    state,
    dispatch,
  };
};

export const assignmentDataSelector = (state: TeacherEvaluationPageState) => state.assignmentData;
// export const assignmentKnowledgePointsSelector = (state: TeacherEvaluationPageState) => {
//   return state.assignmentData?.assignment.knowledgePoints ?? [];
// };

// export const studentGradesSelector = (state: TeacherEvaluationPageState) => {
//   return state.assignmentData?.assignment.studentGrades;
// };

const allStudentsSelector = (state: TeacherEvaluationPageState) =>
  state.assignmentData?.studentArtworks;

export const filteredStudentsSelector = (state: TeacherEvaluationPageState, role: string) => {
  let students = allStudentsSelector(state) ?? [];

  if (state.submissionFilter === 'star') {
    students = students.filter((s) =>
      s.submissions
        ?.map((a) => a?.artworks[0]?.tags?.map((t) => t.name))
        .flat()
        .includes('internal--star'),
    );
  } else if (state.submissionFilter === 'pending') {
    let pendingStatus: SubmissionStatus;
    if (role === 'ta') {
      pendingStatus = SubmissionStatus.Submitted;
    } else if (role === 'teacher') {
      pendingStatus = SubmissionStatus.TaApproved;
    }

    students = students.filter((s) => s.submissions?.[0].submissionStatus === pendingStatus);
  }

  students = students.toSorted((a, b) => {
    switch (state.sortBy) {
      case 'studentNumber':
        return a.studentNumber.localeCompare(b.studentNumber);
      case 'name':
        return a.user.name.localeCompare(b.user.name);
      default:
        return a.studentNumber.localeCompare(b.studentNumber);
    }
  });

  return students;
};

export const activeStudentSelector = (state: TeacherEvaluationPageState, role: string) => {
  return filteredStudentsSelector(state, role)[state.activeStudentIndex];
};

export const activeSubmissionSelector = (state: TeacherEvaluationPageState, role: string) => {
  return activeStudentSelector(state, role)?.submissions?.[state.activeArtworkIndex];
};

export const TeacherEvaluationPageContext = createContext({
  state: initialState,
  currentGroupId: undefined as string | undefined,
  setCurrentGroupId: undefined as undefined | ReturnType<typeof useLocalStorage<string>>[1],
  dispatch: (() => {}) as React.Dispatch<AnyAction>,
  toggleStarStatus: (async () => {}) as (
    submissionId: string,
    artworkId: string,
    current: boolean,
  ) => Promise<void>,
});

export const TeacherEvaluationPageProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  const apollo = useApolloClient();

  const { currentClassId, currentSchoolId, userIdentities, currentIdentity } =
    useStoreContext(IdentityContext);
  const { state, dispatch } = useTeacherEvaluationPageStore();
  const assignmentData = useMemo(() => assignmentDataSelector(state), [state]);

  const lsCurrentGroupStorage = useLocalStorage('CurrentGroupId', '');
  const [lsCurrentGroupId, setLsCurrentGroupId] = lsCurrentGroupStorage;

  const isGroupAssignment = assignmentData?.assignment.isGroupAssignment;

  useEffect(() => {
    if (
      userIdentities?.userAssignmentGroups.filter(
        (uag) =>
          uag.assignmentGroup.schoolId === currentSchoolId &&
          uag.assignmentGroup.assignmentGroupId === lsCurrentGroupId,
      ).length === 0
    ) {
      setLsCurrentGroupId(
        userIdentities.userAssignmentGroups.filter(
          (uag) => uag.assignmentGroup.schoolId === currentSchoolId,
        )?.[0]?.assignmentGroup.assignmentGroupId,
      );
    }
  }, []);

  useEffect(() => {
    if (isGroupAssignment && !lsCurrentGroupId) {
      setLsCurrentGroupId(
        userIdentities.userAssignmentGroups.filter(
          (uag) => uag.assignmentGroup.schoolId === currentSchoolId,
        )?.[0]?.assignmentGroup.assignmentGroupId,
      );
    }
  }, [
    userIdentities,
    assignmentData,
    isGroupAssignment,
    lsCurrentGroupId,
    setLsCurrentGroupId,
    currentSchoolId,
  ]);

  useEffect(() => {
    if (assignmentData) {
      setLsCurrentGroupId(
        userIdentities.userAssignmentGroups.filter(
          (uag) => uag.assignmentGroup.schoolId === currentSchoolId,
        )?.[0]?.assignmentGroup.assignmentGroupId,
      );
    }
  }, [assignmentData, currentSchoolId, setLsCurrentGroupId, userIdentities.userAssignmentGroups]);

  const classId = currentClassId;
  const courseId = searchParams.get('courseId');
  const assignmentId = searchParams.get('assignmentId');

  if (!assignmentId || !courseId) {
    throw new Error('Undefined Parameter');
  }

  const assignmentGroupId = lsCurrentGroupId;

  const filteredStudent = useMemo(
    () => filteredStudentsSelector(state, currentIdentity!.identity),
    [currentIdentity, state],
  );
  // useEffect(() => console.info('filteredStudent', filteredStudent), [filteredStudent]);

  const { loading, data, error, updateQuery } = useQuery(GetTeacherEvaluationPageDocument, {
    variables: {
      assignmentId,
      classId,
      // assignmentGroupId: (isGroupAssignment ? assignmentGroupId : null) ?? null,
    },
  });

  // useEffect(() => console.info('GetTeacherEvaluationPageDocument', data), [data]);

  const [addArtworkTagsMutation] = useMutation(AddArtworkTagsDocument);
  const [deleteArtworkTagsMutation] = useMutation(DeleteArtworkTagsDocument);
  const toggleStarStatus = async (submissionId: string, artworkId: string, current: boolean) => {
    if (current) {
      await deleteArtworkTagsMutation({ variables: { artworkId, tags: ['internal--star'] } });
    } else {
      await addArtworkTagsMutation({ variables: { artworkId, tags: ['internal--star'] } });
    }
    await apollo.resetStore();
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const submission = getFragmentData(
      TeacherEvaluationPageSubmissionFragmentDoc,
      data.submissions,
    );
    const submissionsGroupByStudent = _.groupBy(submission, (s) => s.authors[0].userId);
    const studentArtworks = data.class.classUsers.map((s) => ({
      ...s,
      submissions: submissionsGroupByStudent[s.userId],
    }));

    dispatch(
      actions.setAssignmentData({
        ...data,
        studentArtworks,
      }),
    );
  }, [data, dispatch]);

  if (loading || !assignmentData || !userIdentities) {
    return <Loader fullscreen />;
  }

  if (assignmentData.assignment.isGroupAssignment && !assignmentGroupId) {
    return <Loader fullscreen />;
  }

  return (
    <TeacherEvaluationPageContext.Provider
      value={{
        state,
        currentGroupId: lsCurrentGroupId,
        setCurrentGroupId: setLsCurrentGroupId,
        dispatch,
        toggleStarStatus,
      }}
    >
      {children}
    </TeacherEvaluationPageContext.Provider>
  );
};

type ReducerType<S, A> = [ReducerState<Reducer<S, A>>, Dispatch<ReducerAction<Reducer<S, A>>>];

export const TeacherEvaluationPageActions = actions;
