//@ts-nocheck

import { useEffect } from 'react';
import './styles.scss';

export const Clock = () => {
  useEffect(() => {
    const dialLines = document.getElementsByClassName('diallines');
    const clockEl = document.getElementsByClassName('clock')[0];

    for (let i = 1; i < 60; i++) {
      clockEl.innerHTML += "<div class='diallines'></div>";
      dialLines[i].style.transform = 'rotate(' + 6 * i + 'deg)';
    }

    function clock() {
      let weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        d = new Date(),
        h = d.getHours(),
        m = d.getMinutes(),
        s = d.getSeconds(),
        date = d.getDate(),
        month = d.getMonth() + 1,
        year = d.getFullYear(),
        hDeg = h * 30 + m * (360 / 720),
        mDeg = m * 6 + s * (360 / 3600),
        sDeg = s * 6,
        hEl = document.querySelector('.hour-hand'),
        mEl = document.querySelector('.minute-hand'),
        sEl = document.querySelector('.second-hand'),
        dateEl = document.querySelector('.date'),
        dayEl = document.querySelector('.day');

      const day = weekday[d.getDay()];

      if (month < 9) {
        month = '0' + month;
      }

      hEl.style.transform = 'rotate(' + hDeg + 'deg)';
      mEl.style.transform = 'rotate(' + mDeg + 'deg)';
      sEl.style.transform = 'rotate(' + sDeg + 'deg)';
      dateEl.innerHTML = date + '/' + month + '/' + year;
      dayEl.innerHTML = day;
    }

    const timer = setInterval(clock, 100);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="clock">
      <div>
        <div className="info date"></div>
        <div className="info day"></div>
      </div>
      <div className="dot"></div>
      <div>
        <div className="hour-hand"></div>
        <div className="minute-hand"></div>
        <div className="second-hand"></div>
      </div>
      <div>
        <span className="h3">3</span>
        <span className="h6">6</span>
        <span className="h9">9</span>
        <span className="h12">12</span>
      </div>
      <div className="diallines"></div>
    </div>
  );
};
