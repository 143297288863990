import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RootRouter from './RootRouter';

import GraphQLClient from '../../services/graphql/graphql-client';
import { ApolloProvider } from '@apollo/client';
import { AuthingContextProvider } from './authing.context';
import { IdentityContextProvider } from './identity.context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ToastContainer, toast } from 'react-toastify';
import { PlatformDetector } from './platform';

const queryClient = new QueryClient();

const basename = import.meta.env.ROOT_URL_PATH_NAME as string;

export const App: React.FC = () => {
  return (
    <PlatformDetector>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={GraphQLClient}>
          <AuthingContextProvider>
            <IdentityContextProvider>
              <BrowserRouter basename={basename}>
                {/* <div className="fixed z-[1000] bottom-[10px] left-[10px]">
                V1.0.0 (BUILD: {import.meta.env.COMMIT_SHA}) {isMobileSafari ? 'Mobile Safari' : ''}
              </div> */}
                <RootRouter />
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </BrowserRouter>
            </IdentityContextProvider>
          </AuthingContextProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </PlatformDetector>
  );
};
