import React, { HTMLProps, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const PortalModal: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (!root) {
      return;
    }
    const el = document.createElement('div');
    root.appendChild(el);
    setElement(el);

    return () => {
      root.removeChild(el);
    };
  }, []);

  if (!element) {
    return <div></div>;
  }

  return createPortal(<>{children}</>, element);
};
