import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthingContext } from '../App/authing.context';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { AssignmentUploadPageStoreContext, withStoreProvider } from './store';
import { useStoreContext } from '../../factorys/useStoreContext';
import { ArtworkMediaRender } from '../../components/ArtworkMediaRender';
import ArtworkUpload from '../../components/ArtworkUpload';
import { DeletableArtworkItem } from './components/DeletableArtworkItem';

import CoCALogoPNG from './assets/coca-logo.png';

const ArrowDown = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.52832 1.75766L2.04355 0.242432L7.28593 5.48482L12.5283 0.242432L14.0435 1.75766L7.28593 8.51528L6.52832 7.75766L7.28593 7.00005C6.52832 7.75766 6.52835 7.75769 6.52832 7.75766L0.52832 1.75766Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

const SelectList = ({ show, setShow }: { show?: boolean; setShow: (x: boolean) => void }) => {
  const [current, setCurrent] = useState(0);
  // const [show, setShow] = useState(initOpen);

  useEffect(() => {
    if (!show) {
      return;
    }

    function onClickOutside() {
      setShow(false);
    }
    // console.info('addEventListener onClickOoutside');
    document.addEventListener('click', onClickOutside);

    return () => {
      // console.info('removeEventListener onClickOoutside');
      document.removeEventListener('click', onClickOutside);
    };
  }, [show]);

  if (!show) {
    return <></>;
  }
  return (
    <div className="absolute top-full left-0 w-full py-[7px] bg-[#515151] z-[100]">
      {Array(5)
        .fill(0)
        .map((_, i) => {
          return (
            <div
              key={i}
              className="h-[30px] flex items-center hover:bg-black"
              onClick={(e) => {
                e.stopPropagation();
                setCurrent(i);
              }}
            >
              <div
                className={`${
                  current === i ? 'bg-[#E7380D]' : 'bg-[#7D7D7D]'
                } h-[12px] w-[12px] rounded-full mx-[13px] `}
              ></div>
              {i.toString().padStart(2, '0')} 线的空间
            </div>
          );
        })}
    </div>
  );
};

const AssignmentUploadPage = () => {
  const { user, role, logout, authClient } = useContext(AuthingContext);

  const {
    state: { assignmentUploadPageData: pageData },
  } = useStoreContext(AssignmentUploadPageStoreContext);

  const [showSubjectSelector, setShowSubjectSelector] = useState(false);
  const [showAssignmentSelector, setShowAssignmentSelector] = useState(false);

  const [currentArtworkIndex, setCurrentArtworkIndex] = useState(0);

  const [assignmentState, setAssignmentState] = useState({
    current: '0',
    artworks: [
      {
        id: '0',
        name: '作品0',
      },
      {
        id: '1',
        name: '作品1',
      },
    ],
  });

  const handleAddAssignment = () => {
    setAssignmentState((prev) => {
      const artworks = [
        ...prev.artworks,
        { id: prev.artworks.length.toString(), name: `作品${prev.artworks.length.toString()}` },
      ];
      return { ...prev, artworks };
    });
  };

  const setCurrentAssignment = (current: string) => {
    setAssignmentState((prev) => {
      return { ...prev, current };
    });
  };

  const handleChangeArtworkName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAssignmentState((prev) => {
      const newState = cloneDeep(prev);
      const currentArtworkIndex = prev.artworks.findIndex((a) => a.id === prev.current);
      if (currentArtworkIndex === -1) {
        return prev;
      }

      newState.artworks[currentArtworkIndex].name = e.target.value;

      return newState;
    });
  };
  const currentArtworkName = useMemo(
    () => assignmentState.artworks.find((a) => a.id === assignmentState.current)?.name,
    [assignmentState],
  );

  if (!user || !pageData) {
    return <></>;
  }

  return (
    <div className="bg-[#7d7d7d] h-screen w-screen input:carret-white grid grid-cols-[60%_40%] grid-rows-[95px_calc(100vh-95px)]">
      <header className="h-full col-span-2 flex pl-[130px] pr-[33px] items-center border-b-[1px] border-[#C4C4C4]">
        <div className="h-[55px]">
          <img src={CoCALogoPNG} alt="" />
        </div>
        <div className="h-[50px] flex items-center bg-[#979797] rounded-[25px] pl-[6px] pr-[16px] ml-auto">
          <img className="h-[40px] w-[40px] rounded-[50%]" src={user.photo ?? ''} alt="" />
          <div className="ml-[7px] text-[#c4c4c4]">
            <p>2021 大千班</p>
            <p>{user.nickname ?? user.name}</p>
          </div>
        </div>
        <div
          onClick={logout}
          className="h-[50px] w-[50px] rounded-[50%] bg-[#c4c4c4] ml-[18px]"
        ></div>
      </header>
      <div className="flex flex-col leading-[44px] mt-[46px] ml-[100px] mr-[30px] text-white">
        <div>{pageData?.assignment.name}</div>
        <div className="bg-[#515151] flex-1 overflow-hidden">
          {/* <ArtworkMediaRender
            url={pageData.assignment.artworks[currentArtworkIndex].medias[0].url}
          ></ArtworkMediaRender> */}
        </div>
        <div className="mx-auto leading-[40px]">
          {/* {currentArtworkIndex + 1} / {pageData.assignment.artworks.length} */}
        </div>
        <div className="flex space-x-[7px] mb-[30px]">
          {/* {pageData.assignment.artworks.map((artwork, i) => {
            return (
              <div
                className="flex flex-col items-center"
                key={`artwork-thumb-${i}`}
                onClick={() => setCurrentArtworkIndex(i)}
              >
                <div className="bg-[#515151] h-[80px] w-[80px]">
                  <DeletableArtworkItem
                    assignmentId={pageData.assignment.assignmentId}
                    artwork={artwork}
                  />
                </div>
                <div className="leading-[30px] text-[#C4C4C4]">
                  {(i + 1).toString().padStart(2, '0')}
                </div>
              </div>
            );
          })} */}
          <div className="bg-[#515151] h-[80px] w-[80px]">
            <ArtworkUpload assignmentId={pageData.assignment.assignmentId}></ArtworkUpload>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-[91px] mx-[40px] text-[#C4C4C4] max-w-[400px]">
        <section>
          <header className="border-b-[1px] border-[#C4C4C4] text-[13px]">科 目</header>
          <div className="leading-[40px] text-[18px] relative">
            <div className="flex items-center" onClick={() => setShowSubjectSelector(true)}>
              {/* <ArrowDown className='mr-[10px]'></ArrowDown> */}
              {pageData?.assignment.course.name}
            </div>
            {/* <SelectList show={showSubjectSelector} setShow={setShowSubjectSelector}></SelectList> */}
          </div>
        </section>
        <section className="mt-[20px]">
          <header className="border-b-[1px] border-[#C4C4C4] text-[13px]">作 业</header>
          <div className="leading-[40px] text-[18px] relative">
            <div className="flex items-center" onClick={() => setShowAssignmentSelector(true)}>
              {/* <ArrowDown className='mr-[10px]'></ArrowDown> */}
              {pageData?.assignment.name}
            </div>
            {/* <SelectList show={showAssignmentSelector} setShow={setShowAssignmentSelector}></SelectList> */}
          </div>
          {/* <div className='mt-[20px] flex flex-wrap'>
              {assignmentState.artworks.map((a, i) => {
                return (
                  <div
                    key={i}
                    className={`px-[8px] py-[4px] mr-[5px] mt-[5px] ${
                      a.id === assignmentState.current ? 'bg-[#C4C4C4]' : 'bg-[#979797]'
                    } rounded-[5px] text-black`}
                    onClick={() => setCurrentAssignment(a.id)}
                  >
                    {a.name}
                  </div>
                );
              })}
              <div
                className='px-[8px] py-[4px] mt-[5px] bg-[#E7380D] rounded-[5px] text-white'
                onClick={handleAddAssignment}
              >
                新 增
              </div>
            </div> */}
        </section>
        <section className="mt-[20px]">
          <header className="border-b-[1px] border-[#C4C4C4] text-[13px]">名 称</header>
          <input
            className="h-[45px] bg-transparent leading-[45px] w-full text-[18px]"
            value={currentArtworkName}
            onChange={handleChangeArtworkName}
            title="name"
          ></input>
        </section>
        <section className="mt-[20px]">
          <header className="border-b-[1px] border-[#C4C4C4] text-[13px]">说 明</header>
          <textarea
            title="description"
            className="w-full h-[145px] bg-transparent py-[10px] text-[18px]"
          ></textarea>
        </section>
        <div className="border-t-[1px] border-[#C4C4C4] pt-[56px] mt-auto">
          <button className="bg-[#979797] pl-[6px] pr-[15px] flex rounded-[25px] items-center h-[50px] ml-auto mb-[180px]">
            <div className="w-[40px] h-[40px] rounded-full bg-[#E7380D] flex items-center justify-center">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3669 2.17301C14.3233 2.11911 14.2675 2.07553 14.2038 2.04555C14.1402 2.01558 14.0703 2 13.9994 2C13.9286 2 13.8587 2.01558 13.7951 2.04555C13.7314 2.07553 13.6756 2.11911 13.632 2.17301L7.099 10.1596C7.04514 10.2261 7.01172 10.3059 7.00256 10.3898C6.99341 10.4737 7.00888 10.5585 7.04721 10.6343C7.08555 10.7102 7.1452 10.7741 7.21934 10.8187C7.29348 10.8633 7.37912 10.8869 7.46648 10.8867H11.7771V24.5491C11.7771 24.7971 11.9871 25 12.2437 25H15.7435C16.0002 25 16.2102 24.7971 16.2102 24.5491V10.8924H20.5324C20.9232 10.8924 21.139 10.4584 20.8999 10.1653L14.3669 2.17301Z"
                  fill="#BDBDBD"
                />
              </svg>
            </div>
            <div className="ml-[12px] text-[18px]">提 交</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withStoreProvider(AssignmentUploadPage);
